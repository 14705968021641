import React, { useState, useEffect, useRef, useMemo } from "react";
import AvatarEditor from "react-avatar-editor";
import styled from "styled-components";
/* Components */
import { colors } from "../../../../common/components/colors";
import { FontBody16, FontHeader14, FontSubtitle16, FontTitle34 } from "../../../../common/components/fonts";
import {
    DropdownInput,
    InputLabel,
    TextareaInputNoCountWhite,
    TextInputWhite,
} from "../../../../common/components/inputs";
import { ButtonInactive, ButtonPrimary, ButtonSecondary, ButtonTertiary } from "../../../../common/components/buttons";
import { useDispatch, useSelector } from "react-redux";
import {
    tryDirectoryProfileDetails,
    tryPublishDirectoryProfile,
    trySaveDirectoryProfile,
} from "../middleware/membership";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { tryPostImage } from "../../../../common/containers/imageupload/middleware/images";
import { LogoLoading } from "../../../../common/components/loading";
import { LoadingCenter } from "../../../shop/components/faqs";
import InputArray from "./inputarray";
import { ToolTipBody } from "../../../../common/components/messages";
import { PopupContent, PopupOverlay } from "../../plans/containers/firmplanclientacquisition";
import JoditEditor from "jodit-react";

const BtnDiv = styled.div`
    display: flex;
    gap: 15px;
`;

const StyledImageSelection = styled.div`
    margin: 10px;
`;

const Error = styled.div`
    margin-bottom: 20px;
`;

const ImagePreview = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 10px;
    border: 2px dotted ${colors.primary30};
    img {
        width: 100px;
        height: 100px;
        padding: 10px;
    }
`;

const LogoPreview = styled.div`
    margin: 10px;
    border: 2px dotted ${colors.primary30};
    width: 120px;
    height: 120px;
    padding-right: 5px;

    .logo {
        margin-top: 10px;
        margin-bottom: 30px;
        margin-right: 5px;
    }
    img {
        width: 100px;
        height: 100px;
    }
`;

const Close = styled.div`
    z-index: 8;
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    margin: 0;
`;

const ToolTipContainer = styled.div`
    position: relative;
    ${ToolTipBody} {
        bottom: 60px;
    }
`;
const FitWidth = styled.div`
    width: fit-content;
`;

export default function DirectoryForm(props) {
    const onSave = props.onSave;
    let imageEditor = null;
    const [error, setError] = useState("");
    const [biography, setBiography] = useState("");
    const [firmDesc, setFirmDesc] = useState("");
    const [video, setVideo] = useState("");
    const [logo, setLogo] = useState("");
    const [address, setAddress] = useState("");
    const [firmCRD, setFirmCRD] = useState("");
    const [advisorCRD, setAdvisorCRD] = useState("");
    // const [services, setServices] = useState([]);
    const [expertise, setExpertise] = useState([]);
    const [expertiseInit, setExpertiseInit] = useState([]);
    const [images, setImages] = useState([]);
    const [preview, setPreview] = useState(false);
    const [logoPreview, setLogoPreview] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedLogo, setSelectedLogo] = useState(null);
    const [disclosure, setDisclosure] = useState("");
    const [firmName, setFirmName] = useState("");
    const [clientInfo, setClientInfo] = useState("");
    const dispatch = useDispatch();
    const hiddenFileInput = useRef(null);
    const hiddenLogoInput = useRef(null);
    const [isLoading, setLoading] = useState(false);
    const [mbti, setMBTI] = useState("");
    const [hobby, setHobby] = useState("");
    const [animal, setAnimal] = useState("");
    const [birthorder, setBirthOrder] = useState("");
    const [zodiac, setZodiacSign] = useState(""); // optional
    const [toolTipMBTI, setToolTipMBTI] = useState(false);
    const [toolTipExpertise, setToolTipExpertise] = useState(false);
    const [oldBiography, setOldBiography] = useState("");
    const [oldMBTI, setOldMBTI] = useState("");
    const [oldAnimal, setOldAnimal] = useState("");
    const [oldHobby, setOldHobby] = useState("");
    const [oldBirthOrder, setOldBirthOrder] = useState("");
    const [oldPhotos, setOldPhotos] = useState([]);
    const [oldServices, setOldServices] = useState([]);
    const [oldExpertise, setOldExpertise] = useState([]);
    const [oldDisclosure, setOldDisclosure] = useState("");
    const [oldFirmName, setOldFirmName] = useState("");
    const [oldFirmDesc, setOldFirmDesc] = useState("");
    const [oldFirmLogo, setOldFirmLogo] = useState("");
    const [oldFirmCRD, setOldFirmCRD] = useState("");
    const [oldAdvisorCRD, setOldAdvisorCRD] = useState("");
    const [oldClientInfo, setOldClientInfo] = useState("");
    const [oldAddress, setOldAddress] = useState("");
    const [oldZodiac, setOldZodiac] = useState("");
    const [oldLogo, setOldLogo] = useState("");
    const [oldVideo, setOldVideo] = useState("");
    const isClientAcq = props.isClientAcq;
    const [modal, setModal] = useState(false);
    const userId = useSelector((state) => state.common.user.get("userId"));
    const firstName = useSelector((state) => state.common.user.get("firstName"));
    const lastName = useSelector((state) => state.common.user.get("lastName"));
    const editorBio = useRef(null);
    const editorDis = useRef(null);
    const editorFirmDesc = useRef(null);
    const firmDescConfig = useMemo(
        () => ({
            readonly: false,
            placeholder: "Start typing",
            buttons: ["bold", "italic", "underline", "|", "ul", "ol"],
            height: 300,
            toolbarAdaptive: false,
        }),
        []
    );
    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: "Start typing",
            buttons: ["bold", "italic", "underline", "|", "ul", "ol", "link"],
            height: 300,
            toolbarAdaptive: false,
        }),
        []
    );

    const mbtiTypes = [
        "ISTJ",
        "ISFJ",
        "INFJ",
        "INTJ",
        "ISTP",
        "ISFP",
        "INFP",
        "INTP",
        "ESTP",
        "ESFP",
        "ENFP",
        "ENTP",
        "ESTJ",
        "ESFJ",
        "ENFJ",
        "ENTJ",
    ];

    const zodiacSigns = [
        "Aries",
        "Taurus",
        "Gemini",
        "Cancer",
        "Leo",
        "Virgo",
        "Libra",
        "Scorpio",
        "Sagittarius",
        "Capricorn",
        "Aquarius",
        "Pisces",
    ];

    const birthOrderOptions = ["Oldest", "Only Child", "Middle", "Youngest"];

    const hobbies = [
        "Activism",
        "Art",
        "Baking",
        "Coffee/Tea",
        "Cooking",
        "Crafts",
        "Dancing",
        "Exercise",
        "Spirituality",
        "Friends",
        "Gaming",
        "Gardening",
        "Gym",
        "Knitting/Sewing",
        "Movies/TV",
        "Nutrition",
        "Outdoors",
        "Performance Arts",
        "Photography",
        "Puzzles",
        "Reading",
        "Sports",
        "Travel",
        "Hiking",
        "Wine",
        "Who has time for hobbies?",
    ];

    const animalOptions = [
        "Amphibian",
        "Bear",
        "Bird",
        "Cat",
        "Chicken",
        "Cow",
        "Dog",
        "Dolphin",
        "Elephant",
        "Fish",
        "Fox",
        "Giraffe",
        "Horse",
        "Lion",
        "Monkey",
        "Otter",
        "Pig",
        "Rabbit",
        "Reptiles",
        "Shark",
        "Squirrel",
        "Tiger",
        "Turtle",
        "Whale",
    ];
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        window.scrollTo(0, 0);
        const profile = await dispatch(tryDirectoryProfileDetails());
        if (!isClientAcq) {
            setBiography(profile.intro.description === null ? "" : profile.intro.description);
            setOldBiography(profile.intro.description === null ? "" : profile.intro.description);
        } else {
            setBiography(profile.intro.description === null ? "" : profile.intro.description);
            setOldBiography(profile.intro.description === null ? "" : profile.intro.description);
            setOldMBTI(profile.intro.mbti === null ? "" : profile.intro.mbti);
            setOldAnimal(profile.intro.animal === null ? "" : profile.intro.animal);
            setOldHobby(profile.intro.hobby === null ? "" : profile.intro.hobby);
            setOldBirthOrder(profile.intro.birthOrder === null ? "" : profile.intro.birthOrder);
            setOldPhotos(profile.photos);
            setOldServices(profile.services);
            setOldExpertise(profile.expertise);
            setOldDisclosure(profile.disclosure === null ? "" : profile.disclosure);
            setOldFirmName(profile.firmName === null ? "" : profile.firmName);
            setOldFirmDesc(profile.firmBio === null ? "" : profile.firmBio);
            setOldFirmLogo(profile.firmLogo);
            setOldFirmCRD(profile.firmSec === null ? "" : profile.firmSec);
            setOldAdvisorCRD(profile.advisorSec === null ? "" : profile.advisorSec);
            setOldClientInfo(profile.clientInfo);
            setOldAddress(profile.firmAddress.address === null ? "" : profile.firmAddress.address);
            setOldZodiac(profile.intro.zodiac === null ? "" : profile.intro.zodiac);
            setOldLogo(profile.firmLogo);
            setOldVideo(profile.intro.video === null ? "" : profile.intro.video);
            setFirmDesc(profile.firmBio === null ? "" : profile.firmBio);
            setVideo(profile.intro.video === null ? "" : profile.intro.video);
            // setServices(profile.services);
            setImages(profile.photos);
            setExpertiseInit(profile.expertise);
            setExpertise(profile.expertise);
            setDisclosure(profile.disclosure === null ? "" : profile.disclosure);
            setFirmName(profile.firmName === null ? "" : profile.firmName);
            setAdvisorCRD(profile.advisorSec === null ? "" : profile.advisorSec);
            setFirmCRD(profile.firmSec === null ? "" : profile.firmSec);
            setLogo(profile.firmLogo);
            setAddress(profile.firmAddress.address === null ? "" : profile.firmAddress.address);
            setMBTI(profile.intro.mbti === null ? "" : profile.intro.mbti);
            setZodiacSign(profile.intro.zodiac === null ? "" : profile.intro.zodiac);
            setBirthOrder(profile.intro.birthOrder === null ? "" : profile.intro.birthOrder);
            setAnimal(profile.intro.animal === null ? "" : profile.intro.animal);
            setHobby(profile.intro.hobby === null ? "" : profile.intro.hobby);
            setClientInfo(profile.clientInfo);
        }
    };

    useEffect(() => {
        if (isClientAcq) {
            if (
                biography !== oldBiography ||
                mbti !== oldMBTI ||
                animal !== oldAnimal ||
                hobby !== oldHobby ||
                birthorder !== oldBirthOrder ||
                images !== oldPhotos ||
                expertise !== oldExpertise ||
                disclosure !== oldDisclosure ||
                firmName !== oldFirmName ||
                firmDesc !== oldFirmDesc ||
                logo !== oldFirmLogo ||
                advisorCRD !== oldAdvisorCRD ||
                firmCRD !== oldFirmCRD ||
                clientInfo !== oldClientInfo ||
                address !== oldAddress ||
                zodiac !== oldZodiac ||
                logo !== oldLogo ||
                video !== oldVideo
            ) {
                setError("Save changes to preview your profile page");
            } else {
                setError(
                    biography === "" ||
                        hobby === "" ||
                        mbti === "" ||
                        animal === "" ||
                        birthorder === "" ||
                        images.length === 0
                        ? "init"
                        : ""
                );
            }
        }
    }, [
        biography,
        mbti,
        animal,
        birthorder,
        images,
        hobby,
        expertise,
        disclosure,
        firmName,
        firmDesc,
        logo,
        advisorCRD,
        firmCRD,
        clientInfo,
        address,
        zodiac,
        video,
    ]);

    useEffect(() => {
        if (!isClientAcq) {
            if (biography !== oldBiography) {
                setError("Save changes to preview your profile page");
            } else {
                setError(biography === "" ? "init" : "");
            }
        }
    }, [biography]);
    // const handleServicesChange = (e) => {
    //     let value = e.target.value;
    //     value.split(',');
    //     setServices(value);
    // }
    //console.log(error)

    const handleClick = (event) => {
        setPreview(true);
        hiddenFileInput.current.click();
    };

    const handleClickLogo = (event) => {
        setLogoPreview(true);
        hiddenLogoInput.current.click();
    };

    const handleNewImage = async (e) => {
        setSelectedImage(e.target.files[0]);
    };

    const handleSaveImage = async (e) => {
        setPreview(false);
        let canvasScaled = imageEditor.getImageScaledToCanvas().toDataURL();
        let selected = images;
        selected = selected.concat(canvasScaled);
        await setImages([...selected]);
    };

    const handleSelectedLogos = async (e) => {
        setSelectedLogo(e.target.files[0]);
    };

    const handleSaveLogo = async (e) => {
        setLogoPreview(false);
        let canvasScaled = imageEditor.getImage().toDataURL();
        await setLogo(canvasScaled);
    };

    const handleDelete = async (index) => {
        let selected = images;
        selected.splice(index, 1);
        await setImages([...selected]);
    };

    const handleDeleteLogo = async () => {
        await setLogo(null);
    };

    const handleSave = async () => {
        if (!isClientAcq) {
            if (biography === "") {
                setError("Biography must be filled out");
            } else {
                setLoading(true);
                await dispatch(
                    trySaveDirectoryProfile({
                        id: userId,
                        firstName: firstName,
                        lastName: lastName,
                        description: biography,
                    })
                );
                setError("");
                await onSave();
                setLoading(false);
            }
        } else {
            const imageUrls = await Promise.all(
                images.map(async (img) => {
                    if (img !== null && !img.includes("https")) {
                        console.log(img);
                        return await dispatch(tryPostImage(img));
                    } else {
                        return img;
                    }
                })
            );

            const logoUrls = async () => {
                const img = logo;
                if (img !== null && !img.includes("https")) {
                    return await dispatch(tryPostImage(img));
                } else {
                    return img;
                }
            };
            const logoUrl = await logoUrls();
            setLogo(logoUrl);
            if (biography === "") {
                setError("Biography must be filled out");
            } else if (mbti === "") {
                setError("MBTI must be filled out");
            } else if (animal === "") {
                setError("Animal must be filled out");
            } else if (hobby === "") {
                setError("Hobby must be filled out");
            } else if (birthorder === "") {
                setError("Birth Order must be filled out");
            } else if (images.length === 0) {
                setError("Must have at least one photo");
            } else {
                setLoading(true);
                await dispatch(
                    trySaveDirectoryProfile({
                        id: userId,
                        firstName: firstName,
                        lastName: lastName,
                        photos: imageUrls,
                        intro: {
                            video: video,
                            description: biography,
                            animal: animal,
                            mbti: mbti,
                            hobby: hobby,
                            zodiac: zodiac,
                            birthOrder: birthorder,
                        },
                        expertise: expertise,
                        specialities: [],
                        services: [], //services,
                        disclosure: disclosure,
                        firmSec: firmCRD,
                        advisorSec: advisorCRD,
                        firmName: firmName,
                        firmBio: firmDesc,
                        firmLogo: logoUrl,
                        clientInfo: clientInfo,
                        firmAddress: {
                            address: address,
                        },
                    })
                );
                // const logoUrls = async () => {
                //     const img = logo;
                //     if (img !== null && !img.includes("https")) {
                //         return await dispatch(tryPostImage(img));
                //     } else {
                //         return img;
                //     }
                // };
                // const logoUrl = await logoUrls();
                // setLogo(logoUrl);
                // if (biography === "") {
                //     setError("Biography must be filled out")
                // } else if (mbti === "") {
                //     setError("MBTI must be filled out")
                // } else if (animal === "") {
                //     setError("Animal must be filled out")
                // } else if (hobby === "") {
                //     setError("Hobby must be filled out")
                // } else if (birthorder === "") {
                //     setError("Birth Order must be filled out")
                // } else if (images.length === 0) {
                //     setError("Must have at least one photo")
                // } else {
                //     await dispatch(trySaveDirectoryProfile({
                //         "id": userId,
                //         "firstName": firstName,
                //         "lastName": lastName,
                //         "photos": imageUrls,
                //         "intro": {
                //             "video": video,
                //             "description": biography,
                //             "animal": animal,
                //             "mbti": mbti,
                //             "hobby": hobby,
                //             "zodiac": zodiac,
                //             "birthOrder": birthorder,
                //
                //         },
                //         "expertise": expertise,
                //         "specialities": [],
                //         "services": [], //services,
                //         "disclosure": disclosure,
                //         "logos": [logoUrl],
                //         "firmSec": firmCRD,
                //         "advisorSec": advisorCRD,
                //         "firmName": firmName,
                //         "firmBio": firmDesc,
                //         "firmLogo": logoUrl,
                //         "clientInfo": clientInfo,
                //         "firmAddress": {
                //             address: address
                //         }
                //     }));
                setExpertiseInit(expertise);
                setError("");
                await onSave();
                setLoading(false);
            }
        }
    };

    const showPublishModal = () => {
        setModal(true);
    };

    const cancelPublishModal = () => {
        setModal(false);
    };

    const confirmPublishModal = async () => {
        setLoading(true);
        await props.setPublish();
        setLoading(false);
        setModal(false);
    };

    const pushToPreview = () => {
        let userLink = `https://secure.trustwillow.com/public/advisor-profile-v2/${userId}`;
        window.open(userLink, "_blank");
    };

    const setEditorRef = (editor) => (imageEditor = editor);

    if (isLoading) {
        return (
            <LoadingCenter>
                <LogoLoading />
            </LoadingCenter>
        );
    }
    if (!isClientAcq) {
        return (
            <>
                <br />
                <InputLabel>
                    {" "}
                    <FontBody16 style={{ marginTop: "10px" }}>Biography</FontBody16>
                </InputLabel>
                <JoditEditor
                    ref={editorBio}
                    value={biography}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) => setBiography(newContent)}
                    onChange={(newContent) => {}}
                />
                <br />
                {/*<TextareaInputNoCountWhite title={"My Biography"} valid={(biography !== "")} id={"bio"} onChange={(e) => setBiography(e.target.value)} value={biography} minRows={5} minLength={0} warning={"Required"} />*/}

                <BtnDiv onClick={handleSave}>
                    <ButtonPrimary canSubmit={true} label={"Save"} />
                </BtnDiv>
                {error !== "init" && error !== "Save changes to preview your profile page" ? (
                    <Error>
                        <FontBody16 style={{ color: `${colors.stoplightStop100}` }}>{error}</FontBody16>
                    </Error>
                ) : (
                    <></>
                )}
            </>
        );
    }
    return (
        <>
            {error === "" ? (
                <FitWidth onClick={pushToPreview}>
                    {" "}
                    <ButtonPrimary label={"Preview your profile page"} canSubmit={true} />
                </FitWidth>
            ) : (
                <FitWidth>
                    {" "}
                    <ButtonInactive label={"Preview your profile page"} canSubmit={false} />
                </FitWidth>
            )}
            {error === "Save changes to preview your profile page" && (
                <Error>
                    {" "}
                    <FontBody16 style={{ color: `${colors.stoplightStop100}` }}>{error}</FontBody16>
                </Error>
            )}
            <br />
            <InputLabel>
                <FontBody16>Biography</FontBody16>
            </InputLabel>
            <JoditEditor
                ref={editorBio}
                value={biography}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => setBiography(newContent)}
                onChange={(newContent) => {}}
            />
            {/*<TextareaInputNoCountWhite title={"My Biography"} valid={(biography !== "")} id={"bio"} onChange={(e) => setBiography(e.target.value)} value={biography} minRows={5} minLength={0} warning={"Required"} ref={quillRef}/>*/}
            <ToolTipContainer>
                <ToolTipBody show={toolTipMBTI} onMouseLeave={() => setToolTipMBTI(false)}>
                    <FontSubtitle16>
                        Don't know what your MBTI is? Take a{" "}
                        <a
                            href={"https://www.16personalities.com/personality-types"}
                            target={"_blank"}
                            style={{ color: `${colors.action100}`, textDecoration: "underline" }}
                        >
                            quiz
                        </a>
                    </FontSubtitle16>
                </ToolTipBody>
                <DropdownInput
                    title={
                        <span>
                            Select your MBTI type{" "}
                            <span style={{ width: "50px", height: "50px" }} onMouseEnter={() => setToolTipMBTI(true)}>
                                <InfoCircleOutlined />{" "}
                            </span>
                        </span>
                    }
                    options={mbtiTypes}
                    id={"mbti"}
                    onChange={(e) => setMBTI(e.value)}
                    placeholder={"MBTI"}
                    value={mbti}
                    valid={mbti !== ""}
                    warning={"Required"}
                />
            </ToolTipContainer>
            <DropdownInput
                title={"Select your animal"}
                options={animalOptions}
                id={"animal"}
                onChange={(e) => setAnimal(e.value)}
                placeholder={"Animal"}
                value={animal}
                valid={animal !== ""}
                warning={"Required"}
            />

            <DropdownInput
                title={"Select your favorite Hobby"}
                options={hobbies}
                id={"hobby"}
                onChange={(e) => setHobby(e.value)}
                placeholder={"Hobby"}
                value={hobby}
                valid={hobby !== ""}
                warning={"Required"}
            />

            <DropdownInput
                title={"Select your birth order"}
                options={birthOrderOptions}
                id={"birthorder"}
                onChange={(e) => setBirthOrder(e.value)}
                placeholder={"Birth Order"}
                value={birthorder}
                valid={birthorder !== ""}
                warning={"Required"}
            />

            <DropdownInput
                title={"Select an your Zodiac Sign (optional)"}
                options={zodiacSigns}
                id={"zodiac"}
                onChange={(e) => setZodiacSign(e.value)}
                placeholder={"Zodiac"}
                value={zodiac}
            />
            <ToolTipContainer>
                <ToolTipBody
                    show={toolTipExpertise}
                    onMouseLeave={() => setToolTipExpertise(false)}
                    style={{ bottom: "130px", left: "0", marginRight: "60px" }}
                >
                    <FontSubtitle16>Please add one specialty at a time, then click “add”</FontSubtitle16>
                </ToolTipBody>

                <InputArray
                    title={
                        <span>
                            My Specialty{" "}
                            <span
                                style={{ width: "50px", height: "50px" }}
                                onMouseEnter={() => setToolTipExpertise(true)}
                            >
                                <InfoCircleOutlined />{" "}
                            </span>
                        </span>
                    }
                    save={setExpertise}
                    initData={expertiseInit}
                />
            </ToolTipContainer>
            {/*<TextInputWhite  title={"Services"} valid={true} id={"services"} onChange={(e) => handleServicesChange(e)} value={services} />*/}
            <br />
            <FontBody16>My photos (Select one or more high resolution images)</FontBody16>
            {preview ? (
                <AvatarEditor width={600} height={600} image={selectedImage} ref={setEditorRef} />
            ) : (
                <FontBody16>
                    <br></br>
                    <li>File size should be minimum 400 x 400 pixels</li>
                    <li>File type should be .jpg, .jpeg, .png</li>
                    <li>Use a clear, bright photo without other people in the background</li>
                    {/*<li>*/}
                    {/*    Photo will display as a circle in the directory*/}
                    {/*</li>*/}
                    <br></br>
                </FontBody16>
            )}
            {preview ? (
                <>
                    <StyledImageSelection onClick={handleClick}>
                        <ButtonSecondary label={"Change Photo"} canSubmit={false} />
                    </StyledImageSelection>
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleNewImage}
                        style={{ display: "none" }}
                        multiple
                    />
                </>
            ) : (
                <>
                    <StyledImageSelection onClick={handleClick}>
                        <ButtonSecondary label={"Upload Photo"} canSubmit={false} />
                    </StyledImageSelection>
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleNewImage}
                        style={{ display: "none" }}
                        multiple
                    />
                </>
            )}

            {preview ? (
                <div>
                    <StyledImageSelection onClick={handleSaveImage}>
                        <ButtonTertiary label={"Add"} canSubmit={true} />
                    </StyledImageSelection>
                </div>
            ) : null}
            <ImagePreview>
                {images.map((img, index) => {
                    if (img !== null) {
                        const objectUrl = img instanceof File ? URL.createObjectURL(img) : img;
                        return (
                            <div key={index}>
                                <Close onClick={() => handleDelete(index)}>
                                    <FontHeader14>
                                        <CloseOutlined />
                                    </FontHeader14>
                                </Close>
                                <img src={objectUrl} alt={img.name} />
                            </div>
                        );
                    }
                })}
            </ImagePreview>
            <TextInputWhite
                title={"My Video Link (optional)"}
                valid={video !== ""}
                id={"video"}
                onChange={(e) => setVideo(e.target.value)}
                value={video}
            />

            <br />
            <br />

            <TextInputWhite
                title={"Firm Name"}
                valid={firmName !== ""}
                id={"firmName"}
                onChange={(e) => setFirmName(e.target.value)}
                value={firmName}
            />
            <br />
            <FontBody16>Firm Logo (Select logo with transparent background)</FontBody16>
            {logoPreview ? (
                <AvatarEditor width={600} height={600} image={selectedLogo} ref={setEditorRef} scale={0.7} />
            ) : (
                <></>
            )}
            {logoPreview ? (
                <>
                    <StyledImageSelection onClick={handleClickLogo}>
                        <ButtonSecondary label={"Change Logo"} canSubmit={false} />
                    </StyledImageSelection>
                    <input
                        type="file"
                        ref={hiddenLogoInput}
                        onChange={handleSelectedLogos}
                        style={{ display: "none" }}
                        multiple
                    />
                </>
            ) : (
                <>
                    <StyledImageSelection onClick={handleClickLogo}>
                        <ButtonSecondary label={"Upload Logo"} canSubmit={false} />
                    </StyledImageSelection>
                    <input
                        type="file"
                        ref={hiddenLogoInput}
                        onChange={handleSelectedLogos}
                        style={{ display: "none" }}
                        multiple
                    />
                </>
            )}
            {logoPreview ? (
                <div>
                    <StyledImageSelection onClick={handleSaveLogo}>
                        <ButtonTertiary label={"Save Logo"} canSubmit={true} />
                    </StyledImageSelection>
                </div>
            ) : null}

            <LogoPreview>
                {logo !== null && (
                    <div className={"logo"}>
                        <Close onClick={handleDeleteLogo}>
                            <FontHeader14>
                                <CloseOutlined />
                            </FontHeader14>
                        </Close>
                        <img src={logo instanceof File ? URL.createObjectURL(logo) : logo} alt={logo.name} />
                    </div>
                )}
            </LogoPreview>
            <InputLabel>
                {" "}
                <FontBody16 style={{ marginTop: "10px" }}>Firm Description</FontBody16>
            </InputLabel>
            <JoditEditor
                ref={editorFirmDesc}
                value={firmDesc}
                config={firmDescConfig}
                tabIndex={1}
                onBlur={(newContent) => setFirmDesc(newContent)}
                onChange={(newContent) => {}}
            />
            {/*<TextareaInputNoCountWhite title={"Firm Description"} valid={(firmDesc !== "")} id={"firm"} onChange={(e) => setFirmDesc(e.target.value)} value={firmDesc} minRows={5} minLength={0} />*/}
            <TextInputWhite
                title={"Firm Address"}
                valid={address !== ""}
                id={"address"}
                onChange={(e) => setAddress(e.target.value)}
                value={address}
            />

            <br />
            <br />
            <InputLabel>
                <FontBody16>Disclosure</FontBody16>
            </InputLabel>
            <JoditEditor
                ref={editorDis}
                value={disclosure}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => setDisclosure(newContent)}
                onChange={(newContent) => {}}
            />
            {/*<TextInputWhite title={"Disclosure"} valid={(disclosure !== "")} id={"disclosure"} onChange={(e) => setDisclosure(e.target.value)} value={disclosure} />*/}
            <TextInputWhite
                title={"My SEC/CRD Number"}
                valid={advisorCRD !== ""}
                id={"advisorCRD"}
                onChange={(e) => setAdvisorCRD(e.target.value)}
                value={advisorCRD}
            />
            <TextInputWhite
                title={"Firm SEC/CRD"}
                valid={firmCRD !== ""}
                id={"firmCRD"}
                onChange={(e) => setFirmCRD(e.target.value)}
                value={firmCRD}
            />

            <br />
            {modal && (
                <PopupOverlay>
                    <PopupContent>
                        <>
                            <FontTitle34>Warning!</FontTitle34>
                            <BtnDiv
                                style={{
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                    height: "75%",
                                    margin: "7px 0 0 0",
                                    overflow: "hidden",
                                }}
                            >
                                <FontBody16>Has your compliance team approved of your profile?</FontBody16>
                                <BtnDiv style={{ alignItems: "center" }}>
                                    <BtnDiv onClick={() => cancelPublishModal()}>
                                        <ButtonPrimary label={"No"} canSubmit={false} />
                                    </BtnDiv>
                                    <BtnDiv onClick={() => confirmPublishModal()}>
                                        <ButtonPrimary label={"Yes"} canSubmit={false} />
                                    </BtnDiv>
                                </BtnDiv>
                            </BtnDiv>
                        </>
                    </PopupContent>
                </PopupOverlay>
            )}
            {error !== "init" && error !== "Save changes to preview your profile page" ? (
                <Error>
                    <FontBody16 style={{ color: `${colors.stoplightStop100}` }}>{error}</FontBody16>
                </Error>
            ) : (
                <></>
            )}
            <BtnDiv style={{ alignItems: "center" }}>
                <BtnDiv onClick={() => handleSave()}>
                    <ButtonSecondary canSubmit={true} label={"Save draft"} />
                </BtnDiv>
                <BtnDiv onClick={() => showPublishModal()}>
                    <ButtonPrimary canSubmit={error === ""} label={"Publish"} />
                </BtnDiv>
            </BtnDiv>
        </>
    );
}
